
import {mapMutations, mapState} from "vuex";

export default {
  name: "SubMenuButtons",
  props: ['leftMenu'],
  computed: {
    ...mapState({
      aroundMeAlias: (state) => {
        return state.aroundme.aroundmeAlias
      },
      radioName: (state) => {
        return state.radio.radioName
      }
    })
  },
  methods: {
    ...mapMutations({
      showModal: 'modal/showModal',
    })
  }
}
